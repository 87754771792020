import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner } from '../components/Spinner';
import moment from 'moment';
import { getSite, siteInfo } from '../common/sitesjs';

const Fragment = React.Fragment;

const mapStateToProps = (state) => ({
    forms: state.forms.items,
    loading: state.forms.loading
});

class Home extends React.Component {

    render() {
        const { forms, loading } = this.props;
        const currentSite = getSite();
        const currentSiteInfo = siteInfo[currentSite];

        const isRelevant = (k) => {
            const form = forms[k];
            const dateRelevant = (!form.to && !form.from) || moment(form.to || form.from).isAfter(moment().add(6, 'hour'));
            const siteRelevant = !form.sites || !form.sites.length || form.sites.includes(currentSite)
            return dateRelevant && siteRelevant;
        }

        const sortedForms = Object.keys(forms).filter(isRelevant);
        const any = !!sortedForms.length;
        sortedForms.sort((a, b) => forms[a].from > forms[b].from);

        const loadingMessage = () => <Spinner />;
        const nothingMessage = () => "Nothing is available at this time. Please check again later!";
        const formListItems = () => <Fragment>
            <p>What would you like to register for?</p>
            <ul className="formSelection">
                {
                    sortedForms.map(k =>
                        <li key={k}>
                            <Link to={`/register/${k}`}>
                                <img src={forms[k].image} alt={forms[k].title} />
                            </Link>
                        </li>)
                }
            </ul>
        </ Fragment>;
        const formList = () => (any ? formListItems() : nothingMessage());

        return <div>
            <h1>{currentSiteInfo.heading}</h1>
            {loading
                ? loadingMessage()
                : formList()}
        </div>
    }
}

Home = connect(mapStateToProps)(Home);

export { Home };