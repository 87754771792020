import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { Firestore, getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAGn83vihJiPxTBZY19ECCj6T9IyRpVA-o",
    authDomain: "strengthening-ministries.firebaseapp.com",
    databaseURL: "https://strengthening-ministries.firebaseio.com",
    projectId: "strengthening-ministries",
    storageBucket: "strengthening-ministries.appspot.com",
    messagingSenderId: "428103291142",
    appId: "1:428103291142:web:a53a483242c87f5c274e87",
    measurementId: "G-CYKH8K50R8"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(firebaseApp);


let db: Firestore | undefined;
export function getFirestoreDb() {
    if (!db) {
        db = getFirestore(firebaseApp);
    }
    return db;
}

