import React, { useState, useEffect } from 'react';
import { generatePaymentForm, generatePaymentIdentifier, getRegistrationInfo, RegistrationEntry } from '../actions/api';
import { OptionsDisplay } from './OptionsDisplay';
import { Spinner } from './Spinner';

const fancyIntegration = false;

function safeParse<T>(input?: string): T | undefined {
    if (input) {
        try {
            return JSON.parse(input);
        } catch (e) {
        }
    }
}

function doOnsitePayment(uuid: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
        const args = { uuid };
        console.log("doing onsite payment for", args);
        (window as any).payfast_do_onsite_payment(args, (result: boolean) => {
            resolve(result);
        });
    });
}

function hasPayfast() {
    const hasPayfast = !!(window as any).payfast_do_onsite_payment;
    console.log(`Checking payfast...${hasPayfast}`);
    return hasPayfast;
}


export const Payment = ({ id, forms }: { id: string, forms: any }) => {
    const [paymentDetails, setPaymentDetails] = useState<RegistrationEntry>({ id });
    const [loading, setLoading] = useState(true);
    const [isPayfastLoaded, setIsPayfastLoaded] = useState(hasPayfast());
    const [errorMessage, setErrorMessage] = useState<string>("");

    // make sure the ID gets set
    if (paymentDetails.id !== id) {
        setPaymentDetails({ id });
    }

    // retrieve stuff for this registration
    useEffect(() => {
        const idToGet = id;
        getRegistrationInfo(idToGet).then(registration => {

            if (registration && registration.id) {
                setPaymentDetails(v => {
                    if (registration && v?.id === registration?.id) {
                        return registration;
                    } else {
                        return v;
                    }
                });
            } else {
                console.warn(`Empty response after registration request for ${id}`);
                setErrorMessage("Could not retrieve payment information. Please reload the page");
            }

            setLoading(false);
        }, e => {
            console.error(e);
            setErrorMessage("Could not retrieve payment information. Please reload the page");
            setLoading(false);
        });
    }, [id]);

    // wait for payfast
    useEffect(() => {
        const interval = setInterval(() => {
            if (hasPayfast()) {
                clearInterval(interval);
                setIsPayfastLoaded(true);
            }
        }, 1000);

        return () => clearInterval(interval);
    })

    async function payNowClick(id: string) {
        try {
            setLoading(true);
            if (fancyIntegration) {
                const uuid = await generatePaymentIdentifier(id);
                if (uuid) {
                    const paid = await doOnsitePayment(uuid);
                    setPaymentDetails((v) => ({ ...v, paid }));
                    setLoading(false);
                } else {
                    throw new Error("Could not generate payment UUID")
                }
            } else {
                const formData = await generatePaymentForm(id);
                if (formData) {
                    const form = document.createElement("form");
                    console.log('creating form for', formData);
                    form.style.visibility = 'hidden';
                    form.method = 'POST';
                    form.action = `https://${process.env.REACT_APP_PAYFAST_BASE}/eng/process`;
                    Object.entries(formData).forEach(([key, value]) => {
                        const input = document.createElement('input');
                        input.name = key;
                        input.value = value;
                        form.appendChild(input)
                    });
                    document.body.appendChild(form);
                    console.log('submitting form');
                    form.submit();
                    form.onabort = () => {
                        window.location.reload();
                    }
                }
            }
        } catch (e) {
            setLoading(false);
            console.error(e);
            setErrorMessage("Could not retrieve payment information. Please reload the page");
        }
    }

    console.log(`loading: ${loading}  isPayfastLoaded:${isPayfastLoaded}`)
    if (loading || !isPayfastLoaded) {
        return <Spinner />;
    } else if (errorMessage) {
        return <div className='error'>{errorMessage}</div>
    } else {
        const form = forms[paymentDetails?.form ?? ""];
        const selectedOptions = safeParse<any>(paymentDetails?.options);

        return <div style={{ maxWidth: 500, margin: "auto" }}>
            <OptionsDisplay options={form.options} selection={selectedOptions} titleText="Payment Details" />
            {paymentDetails.paid
                ?
                <div className="form-group paid">
                    Paid, Thank You!
                </div>
                :
                <div className="form-group submit">
                    <button onClick={() => { payNowClick(id); }}>Pay Now</button>
                </div>
            }
        </div>
    }
};