import './styles/main.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './containers/App';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reducer from './reducers';
import thunk from 'redux-thunk';
import { BrowserRouter } from 'react-router-dom';

// where the app will go
const approot = document.getElementById('root');

// the redux store
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));
const store = createStore(reducer, enhancer);

// some helper functions
const reduxify = (App: any): any =>
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>;

const render = (element: any) =>
    ReactDOM.render(element, approot);

render(reduxify(App));
