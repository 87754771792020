
export const sites = ["smti", "acbc"];
export const defaultSite = sites[0];

export const siteInfo = {
    "smti": {
        name: "smti",
        heading: "SMTI Conference Registration",
        image: "/content/smti200.webp",
        host: "register.smti.co.za",
        url: "https://register.smti.co.za",
        organizationShort: "SMTI",
        organization: "Strengthening Ministries Training Institute",
        mainSite: "https://smti.co.za",
        imageFull: "https://register.smti.co.za/content/smti200.webp",
        adminEmail: "SMTI <info@smti.co.za>",
    },
    "acbc": {
        name: "acbc",
        heading: "ACBC Africa Registration",
        image: "/content/acbc200.webp",
        host: "register.acbcafrica.co.za",
        url: "https://register.acbcafrica.co.za",
        organizationShort: "ACBC Africa",
        organization: "ACBC Africa",
        mainSite: "https://acbcafrica.co.za",
        imageFull: "https://register.acbcafrica.co.za/content/acbc200.webp",
        adminEmail: "ACBC Africa <info@acbcafrica.co.za>",
    },
};

export const siteHostMap = {
    "acbcafrica": "acbc",
    "acbcafrica.co.za": "acbc",
    "register.acbcafrica.co.za": "acbc",

    "smti": "smti",
    "smti.co.za": "smti",
    "register.smti.co.za": "smti",
};

export function getSite() {
    // eslint-disable-next-line no-undef
    const hostname = window?.location?.hostname;
    return siteHostMap[hostname] ?? defaultSite;
}
