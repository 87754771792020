import { Link } from "react-router-dom";
import { useConferences } from "../hooks/forms"
import { Spinner } from "./Spinner";


export default function Conferences() {
    const conferences = useConferences();

    if (!conferences) {
        return <Spinner />
    }

    const ca = Object.entries(conferences)
        .map(([, c]) => c)
        .sort((a, b) => {
            if (!a.from) {
                return 1;
            }

            if (!b.from) {
                return -1;
            }

            return b.from.getTime() - a.from.getTime()
        })

    return <div>
        <h1>Conferences</h1><ul>
            {ca.map(c => <li style={{ display: 'block', margin: 50 }} key={c.id}>
                <Link to={`/conferences/${c.id}`}>
                    <img style={{ margin: 'auto', display: 'block' }} alt='' src={c.image} width={500} height={125} />
                </Link>
            </li>)}
        </ul>
    </div>
}
