import { useState } from "react";
import { useConferences, useRegistration } from "../hooks/forms"
import { Spinner } from "./Spinner";
import { updateEntryCancelled, updateEntryPaid } from "../lib/conferences";
import { Link, Redirect } from "react-router-dom";

function Actions(props: { conference: string, registration: string }) {
    const [busy, setBusy] = useState(false)

    const reg = useRegistration(props.conference, props.registration);

    if (!reg) {
        return <div></div>;
    }

    function markPaid() {
        if (busy) {
            return;
        }
        setBusy(true);

        updateEntryPaid(
            props.conference,
            props.registration,
            true)
            .then(() => setBusy(false), () => setBusy(false))
    }

    function cancelReg() {
        if (busy) {
            return;
        }
        setBusy(true);

        updateEntryCancelled(
            props.conference,
            props.registration,
            true)
            .then(() => setBusy(false), () => setBusy(false))

    }

    function uncancelReg() {
        if (busy) {
            return;
        }
        setBusy(true);

        updateEntryCancelled(
            props.conference,
            props.registration,
            false)
            .then(() => setBusy(false), () => setBusy(false))

    }

    function markNotPaid() {
        if (busy) {
            return;
        }
        setBusy(true);

        updateEntryPaid(
            props.conference,
            props.registration,
            false)
            .then(() => setBusy(false), () => setBusy(false))
    }


    return <div>
        <div><button disabled={busy || reg.free || reg.paid || reg.cancelled} onClick={markPaid}>Mark as PAID</button></div>
        <div><button disabled={busy || reg.free || reg.paid || reg.cancelled} onClick={cancelReg}>CANCEL this registration</button></div>
        <div><button disabled={busy || !reg.cancelled} onClick={uncancelReg}>UNCANCEL this registration</button></div>
        <div><button disabled={busy || !reg.paid || reg.free} onClick={markNotPaid}>Mark as NOT PAID</button></div>
        <div><button disabled={busy || (!reg.paid && !reg.free)} onClick={markPaid}>Resend payment confirmation email</button></div>
    </div>
}

export default function Registration(props: { conference: string, registration: string }) {

    const conferences = useConferences()
    const c = conferences?.[props.conference];
    const reg = useRegistration(props.conference, props.registration);

    if (!c) {
        return <Redirect to='/conferences'/>
    }

    if (!reg) {
        return <Spinner />
    }

    let paidText: string;
    let background: string;

    if (reg.free) {
        paidText = 'Free'
        background = 'rgb(100,150,250)'
    } else if (reg.paid) {
        paidText = 'Paid'
        background = 'rgb(150,250,100)'
    } else if (reg.cancelled) {
        paidText = 'Cancelled'
        background = 'rgb(200,200,200)'
    } else {
        paidText = 'Not Paid'
        background = 'rgb(250,100,100)'
    }

    const total = `R ${(reg.total ?? reg.typedOptions.total ?? 0).toFixed(2)}`

    return <div>
        <Link to={`/conferences/${props.conference}`}>{'< Back'}</Link>
        <Link to={`/conferences/${props.conference}`}>
            <img style={{ margin: 'auto', display: 'block', borderBottom: 50 }} alt='' src={c.image} width={500} height={125} />
        </Link>
        <div>{'   '}</div>
        <div style={{
            background: background,
            borderRadius: '10px',
            width: 150,
            height: 50,
            fontSize: '2rem',
            textAlign: 'center',
            margin: '30px auto'
        }}>{paidText}</div>
        <table className="summary">
            <tbody>
                <tr><td>Name</td><td>{reg.fullname}</td></tr>
                <tr><td>Email</td><td>{reg.email}</td></tr>
                <tr><td>Church</td><td>{reg.church}</td></tr>
                <tr><td>Country</td><td>{reg.country}</td></tr>
                <tr><td>Contact</td><td>{reg.contactNumber}</td></tr>
                <tr><td>Total</td><td>{total}</td></tr>
            </tbody>
        </table>

        <Actions conference={props.conference} registration={props.registration} />

    </div>
}
