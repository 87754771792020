import React from 'react';
import { siteInfo, getSite } from '../common/sitesjs';


export const TermsAndConditions = () => {
    
    const currentSiteInfo = siteInfo[getSite()];

    return <div class="tab-content">
        <h1>Terms and Conditions ("Terms")</h1>
        <p>Last updated: February 20, 2018</p>
        <p>
            Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using this website
            ({currentSiteInfo.url}) (the "Service" or "this Website") operated by Strengthening Ministries Training Institute
            ("us", "we", "our", "me", "my", "the owners", or "the owner").
        </p>
        <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These
            Terms apply to all visitors, users and others who access or use the Service.</p>
        <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the
            terms then you may not access the Service.</p>
        <h2>Copyrights and Trademarks</h2>
        <p>
            Unless otherwise noted, all materials including without limitation, logos, brand names, images, designs, photographs,
            video clips and written and other materials that appear as part of this Website are copyrights, trademarks,
            service marks, trade dress and/or other intellectual property whether registered or unregistered ("Intellectual
            Property") owned, controlled or licensed by us. This Website as a whole is protected by copyright and trade
            dress. Nothing on this Website should be construed as granting, by implication, estoppel or otherwise, any
            license or right to use any Intellectual Property displayed or used on this Website, without the prior written
            permission of the Intellectual Property owner. The owners aggressively enforces its intellectual property
            rights to the fullest extent of the law. The names and logos of the owners, may not be used in any way, including
            in advertising or publicity pertaining to distribution of materials on this Website, without prior, written
            permission from the owners. The owners prohibits use of any logo of on this website or any of its affiliates as
            part of a link to or from any Website unless the owners approves such link in advance and in writing. Fair
            use of the owners' Intellectual Property requires proper acknowledgment. Other product and company names
            mentioned in this Website may be the Intellectual Property of their respective owners.
        </p>
        <h2>Links To Other Web Sites</h2>
        <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by the owners.</p>
        <p>The owners has no control over, and assumes no responsibility for, the content, privacy policies, or practices
            of any third party web sites or services. You further acknowledge and agree that The owners shall not be
            responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or
            in connection with use of or reliance on any such content, goods or services available on or through any
            such web sites or services.</p>
        <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or
            services that you visit.</p>
        <h2>
            Governing Law
        </h2>
        <p>These Terms shall be governed and construed in accordance with the laws of South Africa, without regard to its
            conflict of law provisions.</p>

        <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.
            If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions
            of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our
            Service, and supersede and replace any prior agreements we might have between us regarding the Service.</p>

        <h2>No Warranties; Exclusion of Liability; Indemnification</h2>

        <p>OUR WEBSITE IS OPERATED BY Strengthening Ministries Training Institute (THE OWNER) ON AN "AS IS," "AS AVAILABLE" BASIS, WITHOUT REPRESENTATIONS OR WARRANTIES
            OF ANY KIND. TO THE FULLEST EXTENT PERMITTED BY LAW, THE OWNER SPECIFICALLY DISCLAIMS ALL WARRANTIES AND
            CONDITIONS OF ANY KIND, INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT FOR OUR WEBSITE AND ANY CONTRACTS AND SERVICES YOU PURCHASE
            THROUGH IT. THE OWNER SHALL NOT HAVE ANY LIABILITY OR RESPONSIBILITY FOR ANY ERRORS OR OMISSIONS IN THE CONTENT
            OF OUR WEBSITE, FOR CONTRACTS OR SERVICES SOLD THROUGH OUR WEBSITE, FOR YOUR ACTION OR INACTION IN CONNECTION
            WITH OUR WEBSITE OR FOR ANY DAMAGE TO YOUR COMPUTER OR DATA OR ANY OTHER DAMAGE YOU MAY INCUR IN CONNECTION
            WITH OUR WEBSITE. YOUR USE OF OUR WEBSITE AND ANY CONTRACTS OR SERVICES ARE AT YOUR OWN RISK. IN NO EVENT
            SHALL EITHER THE OWNER OR THEIR AGENTS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL
            OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF OUR WEBSITE, CONTRACTS AND
            SERVICES PURCHASED THROUGH OUR WEBSITE, THE DELAY OR INABILITY TO USE OUR WEBSITE OR OTHERWISE ARISING IN
            CONNECTION WITH OUR WEBSITE, CONTRACTS OR RELATED SERVICES, WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY
            OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF ANY SUCH DAMAGES. IN NO EVENT SHALL THE OWNER’S LIABILITY
            FOR ANY DAMAGE CLAIM EXCEED THE AMOUNT PAID BY YOU TO THE OWNER FOR THE TRANSACTION GIVING RISE TO SUCH DAMAGE
            CLAIM.</p>

        <p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
            EXCLUSION MAY NOT APPLY TO YOU.</p>

        <p>WITHOUT LIMITING THE FOREGOING, THE OWNER DOES NOT REPRESENT OR WARRANT THAT THE INFORMATION ON THE WEBITE IS ACCURATE,
            COMPLETE, RELIABLE, USEFUL, TIMELY OR CURRENT OR THAT OUR WEBSITE WILL OPERATE WITHOUT INTERRUPTION OR ERROR.</p>

        <p>YOU AGREE THAT ALL TIMES, YOU WILL LOOK TO ATTORNEYS FROM WHOM YOU PURCHASE SERVICES FOR ANY CLAIMS OF ANY NATURE,
            INCLUDING LOSS, DAMAGE, OR WARRANTY. THE OWNER MAKES NO REPRESENTATION OR
            GUARANTEES ABOUT ANY CONTRACTS AND SERVICES OFFERED THROUGH OUR WEBSITE.</p>

        <p>THE OWNER MAKES NO REPRESENTATION THAT CONTENT PROVIDED ON OUR WEBSITE, CONTRACTS, OR RELATED SERVICES ARE APPLICABLE
            OR APPROPRIATE FOR USE IN ALL JURISDICTIONS.</p>


        <h2>Changes</h2>

        <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is
            material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes
            a material change will be determined at our sole discretion.</p>

        <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the
            revised terms. If you do not agree to the new terms, please stop using the Service.</p>


        <h2>Contact Us</h2>

        <p>If you have any questions about these Terms, please contact us.</p>
    </div>;
}