import { useEffect, useState } from "react";
import { fetchForms } from "../actions/api";
import { Conferences, RegistrationEntry, subscribeEntries, subscribeEntry } from "../lib/conferences";

let conferenceCache: Conferences | undefined;


export function useConferences() {
    const [forms, setForms] = useState(conferenceCache);

    useEffect(() => {
        if (!conferenceCache) {
            fetchForms().then(forms => {
                conferenceCache = forms;
                setForms(forms);
            });
        }
    }, [])

    return forms;
}

export function useRegistrations(conference: string) {
    const [entries, setEntries] = useState<RegistrationEntry[]>([])

    useEffect(() => {
        return subscribeEntries(conference, setEntries)
    }, [conference])

    return entries
}

export function useRegistration(conference: string, registration: string) {
    const [entry, setEntry] = useState<RegistrationEntry | undefined>()

    useEffect(() => {
        return subscribeEntry(conference, registration, setEntry)
    }, [conference, registration])

    return entry;
}