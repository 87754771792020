import React, { useState } from 'react';
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { firebaseApp } from '../lib/firebase'
import { Spinner } from './Spinner';
import { Link } from 'react-router-dom';

const auth = getAuth(firebaseApp);
const provider = new GoogleAuthProvider();


export const Login = () => {
    const [busy, setBusy] = useState(false);
    const [user, setUser] = useState(auth.currentUser);

    function loginClick() {
        setBusy(true);

        signInWithPopup(auth, provider)
            .then((result) => {
                setBusy(false)
                setUser(auth.currentUser);
            }, e => {
                setBusy(false)
                setUser(auth.currentUser);
            });
    }

    if (user) {
        return <>
            <div style={{ margin: '50px', textAlign: 'center' }}>
                You are logged in as <strong>{user.displayName}</strong>
            </div>
            <div style={{ margin: '50px', textAlign: 'center' }}>
                <Link to="/conferences">See Conferences</Link>
            </div>
        </>
    }

    if (busy) {
        return <Spinner />
    }

    return <div>
        <button style={{ margin: "50px auto", display: 'block' }} onClick={loginClick}>LOGIN</button>
    </div>
}