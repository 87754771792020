import { getSite } from '../common/sitesjs';

const moment = require('moment');

const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
const grecaptcha = (window as any).grecaptcha;
const apiBaseUrl = process.env.REACT_APP_API_ROOT;

export interface RegistrationEntry {
    firstname?: string;
    contactNumber?: string;
    registrantEmailAddress?: string;
    email?: string;
    country?: string;
    church?: string;
    childcare?: boolean;

    id: string;
    title?: string;
    lastname?: string;
    options?: string;
    total?: number;
    paid?: boolean;
    free?: boolean;
    cancelled?: boolean;
    timestamp?: Date;
    form?: string;
};

function handleError(e: any) {
    if (typeof e === "string") {
        throw new Error(e);
    }

    throw new Error(e.message || "an error has occurred.");
}

function validateRecaptcha(action = "submit") {
    return new Promise((resolve, reject) => {
        grecaptcha.ready(function () {
            grecaptcha.execute(recaptchaKey, { action })
                .then(resolve, reject);
        });
    });
}

export const fetchForms = () => fetch(`/data/forms.json?${new Date().getTime()}`)
    .then(response => response.json())
    .then(forms => {
        Object.entries(forms).forEach(([id, form]: [string, any]) => {
            form.id = id;
            form.from = form.from && moment(form.from).toDate();
            form.to = form.to && moment(form.to).toDate();

            Object.values(form.options).forEach((option: any) => {
                option.from = option.from && moment(option.from).toDate();
                option.to = option.to && moment(option.to).toDate();
            });
        });

        return forms;
    }).catch(handleError);

export const submitRegistration = (registration: any) =>
    validateRecaptcha("register").then((recaptcha) => fetch(`${apiBaseUrl}/submit`, {
        method: 'POST',
        body: JSON.stringify({ site: getSite(), recaptcha, ...registration }),
        headers: {
            'Content-Type': 'application/json'
        }
    })).then(res => {
        if (!res.ok) {
            return res.text().then(msg => { throw msg || res.statusText || "an error has occurred"; });
        }
    }).catch(handleError);

export const getRegistrationInfo = async (id: string): Promise<RegistrationEntry | undefined> => {
    if (/^(\w{6,16})$/.test(id)) {
        const resp = await fetch(`${apiBaseUrl}/payment/${id}`);
        if (resp.ok) {
            return await resp.json();
        } else {
            return undefined;
        }
    }
}

export const generatePaymentIdentifier = async (id: string): Promise<string | undefined> => {
    if (/^(\w{6,16})$/.test(id)) {
        const resp = await fetch(`${apiBaseUrl}/payment/${id}/generate`, { method: "POST" });
        if (resp.ok) {
            return (await resp.json()).uuid as string;
        } else {
            return undefined;
        }
    }
}

export const generatePaymentForm = async (id: string): Promise<Record<string, string>> => {
    if (/^(\w{6,16})$/.test(id)) {
        const resp = await fetch(`${apiBaseUrl}/payment/${id}/form`, { method: "POST" });
        if (resp.ok) {
            return (await resp.json()) as Record<string, string>;
        }
    }

    return {};
}