import React from 'react';
import { Link } from 'react-router-dom'
import { siteInfo, getSite } from '../common/sitesjs';

export const NotFound = () => {
    const currentSiteInfo = siteInfo[getSite()];
    return <div id="notfound">
        <h1>404 Not Found</h1>
        <p>This page does not exist.</p>
        <a href={currentSiteInfo.mainSite}>Go back to the {currentSiteInfo.organizationShort} website.</a>
        <Link to="/">Go back to the registration page.</Link>
    </div>;
}