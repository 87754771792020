import { collection, onSnapshot, query, doc, updateDoc, DocumentData, DocumentSnapshot } from "firebase/firestore";
import { firebaseApp, getFirestoreDb } from "./firebase";
import { getFunctions, httpsCallable } from "firebase/functions";


export interface Conferences {
    [conference: string]: Conference;
}

export interface Conference {
    id: string;
    image: string;
    sites: Site[];
    title: string;
    sheet: string;
    from?: Date;
    to?: Date;
    location?: string;
    address?: string;
    options: ConferenceOptions;
    online?: boolean;
    link?: string;
    mailTemplateId?: string;
    thankYouTempateId?: string;
    fromEmailAddress?: string;
    childcare?: Childcare;
}

export interface Childcare {
    title: string;
    minAge: number;
    maxAge: number;
    days: Day[];
}

export interface Day {
    date: string;
    slots: string[];
}

export interface ConferenceOption {
    title: string;
    from?: Date;
    to?: Date;
    required?: boolean;
    options: OptionOptions;
    const?: number;
}

export interface ConferenceOptions {
    [option: string]: ConferenceOption;
}

export interface OptionOptions {
    [option: string]: OptionOption;
}

export interface OptionOption {
    title: string;
    cost?: number;
    online?: boolean;
}

export enum Site {
    Acbc = "acbc",
    Smti = "smti",
}

export interface RegistrationOptions {
    [option: string]: any;
    total: number
}

export interface RegistrationEntry {
    id: string;
    cancelled: boolean;
    childcare: boolean;
    church: string;
    contactNumber: string;
    country: string;
    email: string;
    firstname: string;
    form: string;
    free: boolean;
    lastReminder: Date;
    lastname: string;
    numReminders: number;
    options: string;
    typedOptions: RegistrationOptions;
    paid: true;
    site: string;
    total: number;
    fullname: string;
}

function makereg(d: DocumentSnapshot<DocumentData, DocumentData>) {
    const info = d.data() as RegistrationEntry;
    if (!info.typedOptions && typeof info.options === 'string') {
        try {
            info.typedOptions = JSON.parse(info.options) as RegistrationOptions;
        } catch { }
    }

    if (!info.fullname) {
        info.fullname = `${info.firstname} ${info.lastname}`
    }

    return info;
}

export async function updateEntryPaid(conference: string,
    registration: string,
    paid: boolean) {

        const functions = getFunctions(firebaseApp, "europe-west1");
        const registrationHelper = httpsCallable(functions, 'registrationHelper');

    if (paid) {
        await registrationHelper({ conference, registration, action: 'markpaid' });
    } else {
        await registrationHelper({ conference, registration, action: 'marknotpaid' });
    }
}

export async function updateEntryCancelled(conference: string,
    registration: string,
    cancelled: boolean) {


    const db = getFirestoreDb()
    const ref = doc(db, `forms/${conference}/registrations/${registration}`)
    await updateDoc(ref, { cancelled });
}

export function subscribeEntry(
    conference: string,
    id: string,
    onResult: (entry: RegistrationEntry | undefined) => void) {

    if (!conference || !id) {
        onResult(undefined);
        return () => { };
    }

    const db = getFirestoreDb()
    const ref = doc(db, `forms/${conference}/registrations/${id}`)
    const unsubber = onSnapshot(ref, {
        next: d => {
            const entry = makereg(d);
            onResult(entry)
        }
    })

    return unsubber;
}

export function subscribeEntries(id: string,
    onResult: (entry: RegistrationEntry[]) => void,
    onError?: (error: Error) => void): () => void {

    if (!id) {
        onResult([]);
        return () => { };
    }

    const db = getFirestoreDb()
    const col = collection(db, `forms/${id}/registrations`);
    const q = query(col);

    const unsubber = onSnapshot(q, {
        next: snapshot => {
            const entries = snapshot.docs.map(makereg).sort((a, b) => a.fullname.localeCompare(b.fullname))

            onResult(entries)
        },
        error: (e) => {
            console.error(e);
            onError?.(e);
        }
    });

    return unsubber;
}