import React from 'react';
import { Link } from 'react-router-dom'
import { siteInfo } from '../common/sitesjs';

export const Header = ({ site }) => {
    const { heading, image } = siteInfo[site];
    const title = `${heading} Home`;
    return <nav>
        <Link to="/" className="navlogo">
            <img src={image} alt={title} title={title} />
            <span>{heading}</span>
        </Link>
    </nav>
};
