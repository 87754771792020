import { Link, Redirect } from "react-router-dom";
import { useConferences, useRegistrations } from "../hooks/forms"
import { RegistrationEntry } from "../lib/conferences";


function RegistrationEntryButton(props: { conference: string, entry: RegistrationEntry }) {
    const { fullname, id } = props.entry;

    let paidText: string;
    let background: string;

    if (props.entry.free) {
        paidText = 'Free'
        background = 'rgb(100,150,250)'
    } else if (props.entry.paid) {
        paidText = 'Paid'
        background = 'rgb(150,250,100)'
    } else if (props.entry.cancelled) {
        paidText = 'Cancelled'
        background = 'rgb(200,200,200)'
    } else {
        paidText = 'Not Paid'
        background = 'rgb(250,100,100)'
    }

    return <Link to={`/conferences/${props.conference}/registrations/${id}`}>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: '20px auto',
            background: background,
            justifyContent: 'space-between',
            width: 300,
            height: 80,
            padding: 10,
            fontStyle: 'none'
        }}>
            <span style={{}}>{fullname}</span>
            <span style={{ fontWeight: 'bold' }}>{paidText}</span>
        </div>
    </Link>
}

export default function Registrations(props: { id: string }) {
    const conferences = useConferences()
    const c = conferences?.[props.id];
    const entries = useRegistrations(props.id)

    if (!c) {
        return <Redirect to='/conferences'/>
    }

    return <div>
        <Link to={`/conferences`}>{'< Back'}</Link>
        <img style={{ margin: 'auto', display: 'block', borderBottom: 50 }} alt='' src={c.image} width={500} height={125} />
        <ul>
            {entries.map(e => <li key={e.id} style={{
                display: 'block',
                margin: '20px auto',
            }}><RegistrationEntryButton entry={e} conference={props.id} /></li>)}
        </ul>
    </div>
}
