import React from 'react';
import { Link } from 'react-router-dom'
import { siteInfo, getSite } from '../common/sitesjs';

export const RegistrationDone = ({ email, free }) => {
    const currentSiteInfo = siteInfo[getSite()];
    return <div className="registration-success">
        <h1>Check Your Email</h1>
        <p>
            <span>We have sent registration confirmation</span>
            <span>{free ? "" : " and payment information"}</span>
            <span>&nbsp;to&nbsp;</span>
            {email ? <strong>{email}</strong> : <span>your email address</span>}.
        </p>
        <Link to="/" className="button">Register Another</Link>
        <a href={currentSiteInfo.mainSite}>Go back to the {currentSiteInfo.organizationShort} website.</a>
    </div >;
}
